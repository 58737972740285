export default {
  perfumes: {
    en: 'Perfumes',
  },
  allPerfumes: {
    en: 'All perfumes',
  },
  bestsellers: {
    en: 'Best sellers',
  },
  pom: {
    en: 'Perfume of the Month',
  },
  com: {
    en: 'Cologne of the Month',
  },
  clean: {
    en: 'Clean',
  },
  exclusive: {
    en: 'Exclusive',
  },
  collections: {
    en: 'Collections',
  },
  colognes: {
    en: 'Colognes',
  },
  allColognes: {
    en: 'All colognes',
  },
  newArrivals: {
    en: 'New arrivals',
  },
  referral: {
    en: 'Invite your friends and get a free scent',
  },
  newReferral: {
    en: `
      You have
        <span class="{freeFragranceClassName}">
          <span class="rounded-full text-center inline-flex items-center justify-center w-s18 h-s18 text-[11rem]/[18rem] font-bold font-tt-norms-pro {spanClassName}">
            {available}
          </span>
          free {available, plural, one {fragrance} other {fragrances}}
        </span>
      to give away
    `,
  },
  track: {
    en: 'Track your order',
  },
  queue: {
    en: 'Manage your fragrances',
  },
  makeup: {
    en: 'Makeup',
  },
  shop: {
    en: 'Shop',
  },
  shopAll: {
    en: 'Shop all',
  },
  discover: {
    en: 'Discover',
  },
  memberPerks: {
    en: 'Member perks',
  },
  gifts: {
    en: 'Gift Guide',
  },
  giftsMenuEvergreen: {
    en: 'Gifting',
  },
  giftsEvergreen: {
    en: 'The Gift Edit',
  },
  holidayGifts: {
    en: 'Holiday Gifts',
  },
  holidayGiftsMothersDay: {
    en: 'Mother’s Day Gifts',
  },
  holidayGiftsFathersDay: {
    en: 'Father’s Day Gifts',
  },
  exclusives: {
    en: 'Gift guide exclusives',
  },
  allGifts: {
    en: 'All Gifts',
  },
  giftSubscription: {
    en: 'Gift Subscription',
  },
  coarPerfumeGiftSets: {
    en: 'COAR Perfume Gift Sets',
  },
  coarCologneGiftSets: {
    en: 'COAR Cologne Gift Sets',
  },
  coarShopPage: {
    en: 'Confessions&nbsp;of&nbsp;a&nbsp;Rebel',
  },
  sbGiftSets: {
    en: 'Scentbird gift sets',
  },
  giftSetsPage: {
    en: 'Discovery & Gift Sets',
  },
  giftSets: {
    en: 'Gift Sets',
  },
  holidayBoxes: {
    en: 'Holiday boxes',
  },
  giftCard: {
    en: 'Gift Card',
  },
  giftBundles: {
    en: 'Gift bundles',
  },
  mySubscription: {
    en: 'My subscription',
  },
  beauty: {
    en: 'Beauty',
  },
  scentbird: {
    en: 'Scentbird',
  },
  edit: {
    en: 'The Edit',
  },
  theSummerEdit: {
    en: 'The Summer Edit',
  },
  fullSizeFragrances: {
    en: 'Full-size fragrances',
  },
  scentbirdCases: {
    en: 'Scentbird cases',
  },
  bathBody: {
    en: 'Bath & Body',
  },
  accessories: {
    en: 'Accessories',
  },
  samples: {
    en: '1.5 mL samples',
  },
  travelSizeFragrances: {
    en: 'Travel-size fragrances',
  },
  candlesHomeScents: {
    en: 'Candles&nbsp;&amp;&nbsp;Home&nbsp;scents',
  },
  scentProfile: {
    en: 'Scent profile',
  },
  fragranceFamilies: {
    en: 'Fragrance family library',
  },
  fragranceFamiliesArchive: {
    en: 'Fragrance Family Archive',
  },
  allBrands: {
    en: 'All brands',
  },
  careers: {
    en: 'Careers',
  },
  press: {
    en: 'For the press',
  },
  lastMinuteAddons: {
    en: 'Last minute add-ons',
  },
  caseSubscription: {
    en: 'Case subscription',
  },
  candleSubscription: {
    en: 'Candle Club',
  },
  drift: {
    en: 'Drift',
  },
  driftSubscription: {
    en: 'Car Scent Club by Drift',
  },
  buyYourQueue: {
    en: 'Buy your queue and save',
  },
  privateSale: {
    en: 'Private sale',
  },
  referralProgram: {
    en: 'Give a free scent, <span class="text-gold-30">get a free scent</span>',
  },
  aboutScentbird: {
    en: 'About  scentbird',
  },
  support: {
    en: 'Help & Support',
  },
  aboutUs: {
    en: 'About us',
  },
  featuredBrand: {
    en: 'Featured brand',
  },
  fomArchive: {
    en: 'Fragrance of the Month Archive',
  },
  scentWorldPodcast: {
    en: 'Scent World podcast',
  },
  driftEcommercePromo: {
    en: 'Car&nbsp;scents&nbsp;by&nbsp;Drift',
  },
  scentBlog: {
    en: 'Scent blog',
  },
  membership: {
    en: 'Your membership',
  },
  orderHistory: {
    en: 'Order tracking & history',
  },
  manageSubscription: {
    en: 'Manage subscription',
  },
  upgrade: {
    en: 'Upgrade your subscription',
  },
  subscribe: {
    en: 'Subscribe to Scentbird',
  },
  account: {
    en: 'Your account',
  },
  billingInfo: {
    en: 'Billing information',
  },
  shippingInfo: {
    en: 'Shipping information',
  },
  personalInfo: {
    en: 'Personal details',
  },
  reviews: {
    en: 'Ratings & Reviews',
  },
  logoff: {
    en: 'Log out',
  },
  aria: {
    profileButton: {
      en: 'open profile menu',
    },
  },
  labels: {
    subscribed: {
      en: `You're subscribed!`,
    },
    newAtScentbird: {
      en: 'New at scentbird',
    },
    new: {
      en: 'New!',
    },
    sale: {
      en: 'Sale!',
    },
  },
  notesLibrary: {
    en: 'Notes Library',
  },
  scentbirdSelect: {
    en: 'Scentbird Select',
  },
  scentHoroscope: {
    en: 'Scent Horoscope',
  },
  upgrade2Scents: {
    en: '2 Scents a&nbsp;Month',
  },
  upgradeGet2Scents: {
    en: 'Get 2 Scents a&nbsp;Month',
  },
  sale50: {
    en: 'Sale! Up to 50% off',
  },
  giftsUnder50: {
    en: 'Gifts under $50',
  },
  perfumeSubscription: {
    en: 'Perfume subscription',
  },
  palmRoyaleCollaboration: {
    en: 'Apple TV+ Palm Royale',
  },
  randomHouseCollaboration: {
    en: 'Random House Scent Collection',
  },
  bacheloretteCollaboration: {
    en: 'ABC’s The Bachelorette',
  },
  weddingCollaboration: {
    en: 'The #WeddingTok',
  },
  flowers1800Collaboration: {
    en: '1-800-Flowers',
  },
  marvelCollaboration: {
    en: 'MARVEL',
  },
  agathaAllAlongCollaboration: {
    en: 'Agatha All Along',
  },
  cologneSubscription: {
    en: 'Cologne subscription',
  },
}
